import { CurrencyCode } from '@/enums/currency-code.enum';
import type { CurrencyInfoModel } from '@/types/currency/currency-common.type';
import type { DiscountCurrencies } from '@/types/product-discount/product-discount-model.type';
import type { PriceListType } from '@/types/product-selling-price/product-selling-price.type';

import {
  getMinimumFractionDigits,
  getRoundedDownMoney,
  getRoundedUp,
  getUpVndDigitsPrice,
  getVndDigitsPrice,
  truncateToDecimals
} from './currency.util';

export const displayPriceAfterDiscount = (
  price: number,
  discount: number,
  currencyCode: CurrencyCode | string,
  exchangeRate?: number
): number => {
  if (currencyCode === CurrencyCode.Krw) {
    return getRoundedDownMoney(paymentPriceAfterDiscount(price, discount, currencyCode));
  } else {
    const displayPrice = paymentPriceAfterDiscount(price, discount, CurrencyCode.Usd) * (exchangeRate ?? 1);
    if (currencyCode === CurrencyCode.Vnd) {
      return getVndDigitsPrice(displayPrice);
    }

    return truncateToDecimals(displayPrice, getMinimumFractionDigits(currencyCode as CurrencyCode));
  }
};

export const paymentPriceAfterDiscount = (
  price: number,
  discount: number,
  currencyCode: CurrencyCode | string
): number => {
  const paymentPrice = price - (price * (discount || 0)) / 100;
  if (currencyCode === CurrencyCode.Krw) {
    return getRoundedDownMoney(paymentPrice);
  }

  return truncateToDecimals(paymentPrice, getMinimumFractionDigits(currencyCode as CurrencyCode));
};

const getExchangeInfo = (exchangeRates: CurrencyInfoModel[], currencyCode: string) => {
  return exchangeRates?.find((rate: CurrencyInfoModel) => rate.currencyCode === currencyCode);
};

export const getDiscountCurrencies = (
  priceList: PriceListType[],
  discountRate?: number | null,
  exchangeRates?: CurrencyInfoModel[]
): DiscountCurrencies[] => {
  const discount = discountRate || 0;
  return priceList.map((price: PriceListType) => {
    return {
      displayCurrencyCode: price.displayCurrencyCode,
      originalDisplayPrice: price.displayPrice,
      displayPrice: displayPriceAfterDiscount(
        price.salesPrice,
        discount ?? 0,
        price.displayCurrencyCode,
        getExchangeInfo(exchangeRates ?? [], price.displayCurrencyCode)?.exchangeRate
      ),
      originalSalesPrice: price.salesPrice,
      salesPrice: paymentPriceAfterDiscount(
        price.salesPrice,
        discount ?? 0,
        price.salesCurrencyCode ?? CurrencyCode.Usd
      ),
      salesCurrencyCode: price.salesCurrencyCode || ''
    };
  });
};

export const getCollectionDiscountCurrencies = (
  priceList: PriceListType[]
): DiscountCurrencies[] => {
  return priceList.map((price: PriceListType) => {
    return {
      displayCurrencyCode: price.displayCurrencyCode,
      originalDisplayPrice: price.originalDisplayPrice ?? 0,
      displayPrice: displayPriceAfterDiscount(
        price.displayPrice,
        0,
        price.displayCurrencyCode,
        1
      ),
      originalSalesPrice: price.originalSalesPrice ?? 0,
      salesPrice: paymentPriceAfterDiscount(
        price.salesPrice,
        0,
        price.salesCurrencyCode ?? CurrencyCode.Usd
      ),
      salesCurrencyCode: price.salesCurrencyCode || ''
    };
  });
};

export const getDiscountAmount = (
  price: number,
  discount: number,
  currencyCode: CurrencyCode | string
): number => {
  const displayPrice = (price * (discount || 0)) / 100;
  if (currencyCode === CurrencyCode.Krw) {
    return getRoundedUp(displayPrice, 1);
  }
  if (currencyCode === CurrencyCode.Vnd) {
    return getUpVndDigitsPrice(displayPrice);
  }

  return getRoundedUp(displayPrice, -getMinimumFractionDigits(currencyCode as CurrencyCode));
};
